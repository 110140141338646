// http://ionicframework.com/docs/theming/
@import "@ionic/angular/css/core.css";
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";

@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";
@import "@ionic/angular/css/display.css";

.invisible {
  visibility: hidden !important;
}

.hidden-xs-up {
  display: none !important;
}

@media (max-width: 575px) {
  .hidden-xs-down {
    display: none !important;
  }
}

@media (min-width: 576px) {
  .hidden-sm-up {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .hidden-md-down {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .hidden-lg-up {
    display: none !important;
  }
}

@media (max-width: 1199px) {
  .hidden-lg-down {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-xl-up {
    display: none !important;
  }
}

.hidden-xl-down {
  display: none !important;
}

.bar-button {
  color: #fff;
}

.navbar-logo {
  height: 36px;
  width: 129.7px;
}

.toolbar-title {
  color: white;
}

.inner-content {
  padding: 16px;
  min-height: calc(90% - 56px);
}

.menu-content {
  ion-list {
    background-color: white;
  }
}

ion-content {
  background-color: var(--ffs-color-primary);
  --background: #46c6fc linear-gradient(#cdeffe, #04b1fb);
}

.card {
  margin: 0px;
  width: 100%;
  background-color: #ffffff;

  ion-card {
    margin: 0px;
  }

  ion-card-header {
    background-color: var(--ion-color-tertiary);

    ion-card-title {
      color: white;
      font-size: 1.0rem;
    }
  }

  ion-card-content {
    padding: 0px;
    background-color: transparent;
  }

  ion-label {
    margin: 4px 8px 4px 8px;
  }

  [item-end] {
    margin: 0px;
  }

  td {
    word-break: break-all;
  }
}

.gold {
  color: #ffcc3c;
}

.silver {
  color: #cdd0d7;
}

.bronze {
  color: #cd7f32;
}

.submitBtn {
  margin-top: 12px;

  .button-inner {
    height: 2rem;
    font-size: 1.6rem;
  }
}

.error-text {
  color: var(--ion-color-danger);
}

.menu {
  ion-item {
    cursor: pointer;
  }
}

.input-form {
  ion-label {
    margin: inherit;
    color: var(--ion-color-dark-tint) !important;
  }

  ion-input {
    color: var(--ion-color-dark) !important;
  }
}

.answerInput {
  font-size: 1.2rem;

  ion-input {
    --padding-top: 0px;
    --padding-bottom: 0px;
  }
}

.green-text {
  color: var(--ion-color-secondary-shade);
}

.grey-text {
  color: gray;
}

.red {
  color: var(--ion-color-danger);
}

.leave-button {
  span {
    color: var(--ion-color-danger);
  }
}

h3 {
  font-size: 1.4rem !important;
  color: var(--ion-color-secondary-shade) !important;
}

button.alert-button.alert-delete-button {
  color: #FF0000;
}

ion-popover ion-content {
  --background: #ffffff;
}
